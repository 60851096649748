import React from "react";
import { PAGE_COVER, VIGNETTE } from "./elements";

const PageCover = () => {
  return (
    <PAGE_COVER>
      <VIGNETTE />
    </PAGE_COVER>
  );
};

export default PageCover;
