import { Fragment, useEffect, useState } from "react";
import { timeIcon } from "../../../../../assets/images/icons";

const tarkovRatio = 7;
const Time = () => {
  const hrs = (num) => {
    return 1000 * 60 * 60 * num;
  };
  const realTimeToTarkovTime = (time, left) => {
    const oneDay = hrs(24);
    const russia = hrs(3);
    const offset = russia + (left ? 0 : hrs(12));
    const tarkovTime = new Date(
      (offset + time.getTime() * tarkovRatio) % oneDay
    );
    return tarkovTime;
  };
  const formatHMS = (date) => {
    return [date.getUTCHours(), date.getUTCMinutes()]
      .map((x) => x.toString().padStart(2, "0"))
      .join(":");
  };
  const TarkovCurrentTime = ({ tarkovTime }) => {
    return <Fragment>{formatHMS(tarkovTime)}</Fragment>;
  };

  const [time, setTime] = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 50);
  }, []);

  const tarkovTimeLeft = realTimeToTarkovTime(time, false);
  const tarkovTimeRight = realTimeToTarkovTime(time, true);
  return (
    <>
      <div className="time">
        <span className="txt">
          Raid <img src={timeIcon} width={40} alt="" /> :
        </span>
        <span className="el">
          <TarkovCurrentTime tarkovTime={tarkovTimeLeft} />
        </span>
        <span className="el">
          <TarkovCurrentTime tarkovTime={tarkovTimeRight} />
        </span>
      </div>
    </>
  );
};

export { Time };
