import React from "react";
import { Link } from "react-router-dom";

const PleaseLoginMessage = () => {
  return (
    <>
      Please <Link to="/login">log in</Link> to access the dashboard.
    </>
  );
};

export default PleaseLoginMessage;
