import { ToastErrorDefault } from "../../../global/modules/elements";

const handlePmcLevelChange = (e, setPmcLevelInput) => {
  const inputValue = e.target.value;

  if (inputValue.startsWith("0") || parseInt(inputValue) > 79) {
    ToastErrorDefault("PMC Level cannot start with 0 or be greater than 79");
  } else {
    setPmcLevelInput(inputValue);
  }
};

export default handlePmcLevelChange;