import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CheckIcon from "@mui/icons-material/Check";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { LoaderFormatted } from "../../../global/modules";

const TaskDetail = () => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [removedObjectives, setRemovedObjectives] = useState([]);
  const [objectiveOrder, setObjectiveOrder] = useState([]);
  const [activeTab, setActiveTab] = useState("description");

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const taskDocRef = doc(db, "tasks", taskId);
        const taskSnapshot = await getDoc(taskDocRef);

        if (taskSnapshot.exists()) {
          setTask(taskSnapshot.data());
          setObjectiveOrder(
            taskSnapshot.data().objectives.map((_, index) => index)
          );
        } else {
          console.error("Task not found.");
        }
      } catch (error) {
        console.error("Error fetching task: ", error);
      } finally {
        setIsLoading(false); // Set isLoading to false when fetch is completed
      }
    };

    fetchTask();
  }, [taskId]);

  const removeObjective = (index) => {
    const updatedRemovedObjectives = [...removedObjectives, index];
    setRemovedObjectives(updatedRemovedObjectives);
    localStorage.setItem(
      "removedObjectives",
      JSON.stringify(updatedRemovedObjectives)
    );
  };

  const copyObjectiveText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedObjectives = [...objectiveOrder];
    const [movedObjective] = reorderedObjectives.splice(result.source.index, 1);
    reorderedObjectives.splice(result.destination.index, 0, movedObjective);

    setObjectiveOrder(reorderedObjectives);
  };

  useEffect(() => {
    const storedRemovedObjectives = localStorage.getItem("removedObjectives");
    if (storedRemovedObjectives) {
      setRemovedObjectives(JSON.parse(storedRemovedObjectives));
    }
  }, []);

  if (!task) {
    return <div>Loading...</div>;
  }

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  if (isLoading) {
    return (
      <>
        <LoaderFormatted />
      </>
    );
  }

  return (
    <div className="subpage__wrapper subpage__task-details">
      <h1>Task Details</h1>
      <div className="task-details" style={{ zIndex: "+2" }}>
        <div className="title">
          <h2>{task.title}</h2>
        </div>
        <div className="tab-navigation">
          <button
            className={`tab-button ${
              activeTab === "description" ? "active" : ""
            }`}
            onClick={() => toggleTab("description")}
          >
            Description
          </button>
          <button
            className={`tab-button ${activeTab === "message" ? "active" : ""}`}
            onClick={() => toggleTab("message")}
          >
            Message
          </button>
        </div>
        {activeTab === "description" && (
          <div className="tab-content">
            <p className="taskMessage">{task.description}</p>
          </div>
        )}
        {activeTab === "message" && (
          <div className="tab-content">
            <p className="taskMessage">{task.taskMessage}</p>
          </div>
        )}
        <div className="taskObjectives">
          <h3>Objectives:</h3>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="objectives">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="objectives__container"
                >
                  {objectiveOrder.map((objectiveIndex, index) => {
                    const objective = task.objectives[objectiveIndex];
                    if (!removedObjectives.includes(objectiveIndex)) {
                      return (
                        <Draggable
                          key={objectiveIndex}
                          draggableId={`objective-${objectiveIndex.toString()}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              className="objective"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {objective}
                              <div className="actions">
                                <button
                                  className="copy"
                                  onClick={() => copyObjectiveText(objective)}
                                >
                                  <FileCopyIcon className="icon" />
                                </button>
                                <button
                                  className="check"
                                  onClick={() =>
                                    removeObjective(objectiveIndex)
                                  }
                                >
                                  <CheckIcon className="icon" />
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                    return null;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="rewards">
          <h3>Rewards:</h3>
          <div className="rewards__container">
            {task.rewards.map((reward, index) => (
              <span key={index} className="reward">
                {reward}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;
