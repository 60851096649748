import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../../global/modules/elements";

const handleTaskSubmit = async (
  e,
  taskTrader,
  taskTitle,
  taskMessage,
  taskDescription,
  taskObjective,
  rewards,
  levelRequirement,
  requirements,
  setTaskTrader,
  setTaskTitle,
  setTaskMessage,
  setRewards,
  setRewardInput,
  setTaskDescription,
  setTaskObjective,
  setTaskObjectiveInput,
  setRequirements,
  setRequirementsInput,
  setLevelRequirement
) => {
  e.preventDefault();

  try {
    const docRef = await addDoc(collection(db, "tasks"), {
      trader: taskTrader,
      title: taskTitle,
      taskMessage: taskMessage,
      description: taskDescription,
      objectives: taskObjective,
      rewards: rewards,
      levelRequirement: levelRequirement,
      requirements: requirements,
    });

    console.log("Task added with ID: ", docRef.id);

    setTaskTrader("");
    setTaskTitle("");
    setTaskMessage("");
    setRewards([]);
    setRewardInput("");
    setTaskDescription("");
    setTaskObjective([]);
    setTaskObjectiveInput("");
    setRequirements([]);
    setRequirementsInput("");
    setLevelRequirement("");

    ToastErrorDefault("Task added with ID: " + docRef.id);
  } catch (error) {
    console.error("Error adding task: ", error);
  }
};

export default handleTaskSubmit;
