import React from "react";

const TaskFormDropdown = ({ value, onChange }) => {
  return (
    <div className="dropdown__taskform">
      <label htmlFor="trader">Select Trader:</label>
      <select
        id="trader"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Select a Trader</option>
        <option value="Prapor">Prapor</option>
        <option value="Therapist">Therapist</option>
        <option value="Skier">Skier</option>
        <option value="Peacekeeper">Peacekeeper</option>
        <option value="Mechanic">Mechanic</option>
        <option value="Ragman">Ragman</option>
        <option value="Jaeger">Jaeger</option>
      </select>
    </div>
  );
};

export default TaskFormDropdown;
