import React from "react";

const Items = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <li key={item.id}>
          <div className="details">
            <img src={item.iconLink} alt="" />
            <p>ID: {item.id}</p>
            <p>Name: {item.name}</p>
            <p>Short Name: {item.shortName}</p>
            <div className="links">
              <a href={item.wikiLink}>Wiki Page</a>
            </div>
            <p>Updated: {new Date(item.updated).toLocaleString()}</p>
          </div>
        </li>
      ))}
    </>
  );
};

export default Items;
