import { query, collection, getDocs, where, updateDoc } from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const updatePmcLevel = async (user, pmcLevelInput, setPmcLevel) => {
  try {
    if (!user) return;

    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0].ref;

      await updateDoc(userDoc, { pmcLevel: pmcLevelInput });
      setPmcLevel(pmcLevelInput);

      if (pmcLevelInput < 2) {
        console.log("PMC Level is less than 2");
      }
    } else {
      console.error("User document not found.");
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while updating PMC level");
  }
};

export default updatePmcLevel;
