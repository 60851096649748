import React from "react";
import { A, BOTTOM_WRAPPER, WRAPPER_LINKS } from "./elements";
import {
  infoIcon,
  microphoneIcon,
  wrenchIcon,
} from "../../../../assets/images/icons";

const Bottom = () => {
  return (
    <BOTTOM_WRAPPER>
      <WRAPPER_LINKS>
        <A href="/info">
          <img src={infoIcon} width={20} alt="Info" /> Our info page (about EFT)
        </A>
      </WRAPPER_LINKS>
      <WRAPPER_LINKS>
        <A href="https://tarkov.support">
          <img src={wrenchIcon} width={20} alt="Support" /> Tarkov Support
        </A>
        <A href="https://tarkov.press">
          <img src={microphoneIcon} width={20} alt="Microphone" /> Tarkov Press
        </A>
        <A href="https://tarkov.com">Escape from Tarkov Official Site</A>
      </WRAPPER_LINKS>
    </BOTTOM_WRAPPER>
  );
};

export default Bottom;
