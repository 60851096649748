import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const DashboardAction = ({ user }) => {
  const [isLoggedIn, setIsLoggedIn] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setIsLoggedIn("Dashboard");
    }
  }, [user, navigate]);
  return (
    <>
      {isLoggedIn ? (
        <Link className="page__login item" to="/dashboard">
          Dashboard
        </Link>
      ) : (
        <Link className="page__login item" to="/login">
          Login
        </Link>
      )}
    </>
  );
};

export default DashboardAction;
