import {
  query,
  collection,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const incrementTaskProgress = async (user, taskName, maxProgress, itemId, taskProgress, setTaskProgress) => {
  try {
    if (!user) return;

    const userRef = collection(db, "users");
    const q = query(userRef, where("uid", "==", user?.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];

      const userDocData = userDoc.data();
      const userTaskProgress = userDocData.taskProgress || {};

      const itemKey = `${taskName}-${itemId}`;

      if (!userTaskProgress.hasOwnProperty(itemKey)) {
        userTaskProgress[itemKey] = 1;
      } else {
        userTaskProgress[itemKey] += 1;

        if (userTaskProgress[itemKey] > maxProgress) {
          userTaskProgress[itemKey] = maxProgress;
        }
      }

      await updateDoc(userDoc.ref, { taskProgress: userTaskProgress });

      setTaskProgress({
        ...taskProgress,
        [itemKey]: userTaskProgress[itemKey],
      });
    } else {
      console.error("User document not found.");
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while incrementing task progress.");
  }
};

export default incrementTaskProgress;
