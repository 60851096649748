import React from "react";

const SetTarkovEditionButton = ({
  user,
  tarkovEditionInput,
  setTarkovEdition,
  updateTarkovEdition,
}) => {
  return (
    <button
      className="dashboard__btn"
      onClick={() =>
        updateTarkovEdition(user, tarkovEditionInput, setTarkovEdition)
      }
    >
      Set Tarkov Edition
    </button>
  );
};

export default SetTarkovEditionButton;
