import { Link } from "react-router-dom";

const TraderIcon = ({ icon, name, link }) => {
  return (
    <Link className="trader" to={link}>
      <img src={icon} alt="" />
      <div className="name">{name}</div>
    </Link>
  );
};

export default TraderIcon;
