import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./lib/styles/global/global.scss";
import { Layout, LoaderFormatted, NoPage } from "./lib/components/global/modules";
import { ToastInfoDefault } from "./lib/components/global/modules/elements";
import { Home, Info, Login, Register, Reset } from "./lib/components/pages";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./lib/hooks/firebase";
import { DashboardLayout } from "./lib/components/pages/dashboard/modules";
import TaskForm from "./lib/components/pages/admin/pages/TaskForm";
import Dashboard from "./lib/components/pages/dashboard/Dashboard";
import { TaskDetail, TraderPage } from "./lib/components/pages/dashboard/pages";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { DevelopmentNotice } from "./lib/data";
import { allowedUserUids } from "./lib/data";

const PrivateRoute = ({ element }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null;
  }

  if (user) {
    return element;
  }

  return <Navigate to="/login" />;
};

const AdminPrivateRoute = ({ element }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null;
  }

  if (user && allowedUserUids.includes(user.uid)) {
    return element;
  } else {
    return <Navigate to="/login" replace />;
  }
};

function App() {
  useEffect(() => {
    ToastInfoDefault(DevelopmentNotice);
  }, []);
  return (
    <React.Suspense fallback={<LoaderFormatted />}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="dashboard">
            <Route index element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="prapor/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute element={<TraderPage traderName="prapor" />} />
                }
              />
            </Route>
            <Route path="therapist/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute
                    element={<TraderPage traderName="therapist" />}
                  />
                }
              />
            </Route>
            <Route path="fence/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute element={<TraderPage traderName="fence" />} />
                }
              />
            </Route>
            <Route path="skier/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute element={<TraderPage traderName="skier" />} />
                }
              />
            </Route>
            <Route path="peacekeeper/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute
                    element={<TraderPage traderName="peacekeeper" />}
                  />
                }
              />
            </Route>
            <Route path="mechanic/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute
                    element={<TraderPage traderName="mechanic" />}
                  />
                }
              />
            </Route>
            <Route path="ragman/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute element={<TraderPage traderName="ragman" />} />
                }
              />
            </Route>
            <Route path="jaeger/*" element={<DashboardLayout />}>
              <Route
                index
                element={
                  <PrivateRoute element={<TraderPage traderName="jaeger" />} />
                }
              />
            </Route>
            <Route path=":traderName/:taskId" element={<DashboardLayout />}>
              <Route
                index
                element={<PrivateRoute element={<TaskDetail />} />}
              />
            </Route>
          </Route>

          <Route path="admin">
            <Route
              path="task-form"
              element={
                <AdminPrivateRoute
                  element={<TaskForm />}
                  adminRequired={true}
                />
              }
            />
          </Route>
          <Route path="info" element={<Info />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default App;
