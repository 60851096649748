const addRequirement = (
  requirementsInput,
  requirements,
  setRequirements,
  setRequirementsInput
) => {
  if (requirementsInput.trim() !== "") {
    setRequirements([...requirements, requirementsInput]);
    setRequirementsInput("");
  }
};

export default addRequirement;
