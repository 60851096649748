import React from "react";

const REGISTER_BTN = ({ register, name, email, password }) => {
  return (
    <button
      className="register__btn"
      onClick={() => register(name, email, password)}
    >
      Register
    </button>
  );
};

export default REGISTER_BTN;
