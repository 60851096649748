import React from "react";
import { signInWithGoogle } from "../../../../../../hooks/firebase";
import { googleLogo } from "../../../../../../assets/images/icons";

const RegisterWithGoogle = () => {
  return (
    <button
      className="register__btn register__google"
      onClick={signInWithGoogle}
    >
      <img src={googleLogo} alt="Google Logo" />
      Register with Google
    </button>
  );
};

export default RegisterWithGoogle;
