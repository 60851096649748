import React from "react";

const INPUT = ({ type, value, onChange, placeholder }) => {
  return (
    <input
      type={type}
      className="register__textBox"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default INPUT;
