import { query, collection, getDocs, where, updateDoc } from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const updateTarkovEdition = async (user, tarkovEditionInput, setTarkovEdition) => {
  try {
    if (!user) return;

    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0].ref;

      await updateDoc(userDoc, { tarkovEdition: tarkovEditionInput });
      setTarkovEdition(tarkovEditionInput);
    } else {
      console.error("User document not found.");
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while updating Tarkov Edition");
  }
};

export default updateTarkovEdition;
