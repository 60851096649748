import React from "react";
import { Outlet } from "react-router-dom";
import { BackButton } from "../../../global/modules/elements";

const DashboardLayout = () => {
  return (
    <div className="container__page dashboard subpage">
      <BackButton />
      <Outlet />
    </div>
  );
};

export default DashboardLayout;
