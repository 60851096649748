import { logInWithEmailAndPassword } from "../../../../../hooks/firebase";

const handleLogin = async (email, password) => {
  if (!email || !password) {
    alert("Please enter both email and password.");
    return;
  }

  try {
    logInWithEmailAndPassword(email, password);
  } catch (error) {
    alert("Login failed: " + error.message);
    console.error("Login error:", error);
  }
};

export default handleLogin;
