import {
  query,
  collection,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const resetTaskProgress = async (user, setTaskProgress) => {
  try {
    if (!user) return;

    const userRef = collection(db, "users");
    const q = query(userRef, where("uid", "==", user?.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];

      const userDocData = userDoc.data();
      const userTaskProgress = userDocData.taskProgress || {};

      for (const key in userTaskProgress) {
        userTaskProgress[key] = 0;
      }

      await updateDoc(userDoc.ref, { taskProgress: userTaskProgress });

      setTaskProgress(userTaskProgress);
    } else {
      console.error("User document not found.");
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while resetting task progress.");
  }
};

export default resetTaskProgress;
