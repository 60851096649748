import BackButton from "./elements/BackButton";
import NO_PAGE_CONTAINER from "./elements/NO_PAGE_CONTAINER";
import SUBPAGE_WRAPPER from "./elements/SUBPAGE_WRAPPER";
import H2 from "./elements/H2";
import SPAN_PRIMARY_COLOR from "./elements/SPAN_PRIMARY_COLOR";
import { currentPath } from "../functions";

const NoPage = () => {
  return (
    <>
      <NO_PAGE_CONTAINER>
        <BackButton />
        <SUBPAGE_WRAPPER>
          <H2>
            "<SPAN_PRIMARY_COLOR>{currentPath()}</SPAN_PRIMARY_COLOR>" does not
            exist.
          </H2>
        </SUBPAGE_WRAPPER>
      </NO_PAGE_CONTAINER>
    </>
  );
};

export default NoPage;
