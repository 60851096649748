import React from "react";
import { signInWithGoogle } from "../../../../hooks/firebase";
import { googleLogo } from "../../../../assets/images/icons";

const LoginWithGoogle = () => {
  return (
    <button className="login__btn login__google" onClick={signInWithGoogle}>
      <img src={googleLogo} alt="Google Logo" />
      Login with Google
    </button>
  );
};

export default LoginWithGoogle;
