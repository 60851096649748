import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../../hooks/firebase";
import {
  query,
  collection,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { db } from "../../../../hooks/firebase";

import { handleLogin } from "./functions";

import { LoaderFormatted } from "../../../global/modules";
import { BackButton, LoginWithGoogle } from "../../../global/modules/elements";
import {
  H1,
  INPUT,
  LOGIN_BTN,
  LOGIN_CONTAINER,
  PAGE_WRAPPER,
} from "./modules/elements";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      getDocs(q)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();

            if (!docData.pmcLevel) {
              updateDoc(querySnapshot.docs[0].ref, { pmcLevel: 1 });
            }

            if (!docData.tarkovEdition) {
              updateDoc(querySnapshot.docs[0].ref, {
                tarkovEdition: "Standard Edition",
              });
            }
          }

          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [user, loading, navigate]);

  return (
    <>
      {loading ? (
        <>
          <LoaderFormatted />
        </>
      ) : (
        <>
          <LOGIN_CONTAINER>
            <BackButton />
            <PAGE_WRAPPER>
              <H1>Login</H1>
              <INPUT
                type="text"
                value={email}
                onChange={setEmail}
                placeholder="E-mail Address"
              />
              <INPUT
                type="password"
                value={password}
                onChange={setPassword}
                placeholder="Password"
              />
              <LOGIN_BTN
                handleLogin={handleLogin}
                email={email}
                password={password}
              />
              <LoginWithGoogle />
              <div>
                <Link to="/reset">Forgot Password</Link>
              </div>
              <div>
                Don't have an account? <Link to="/register">Register</Link> now.
              </div>
            </PAGE_WRAPPER>
          </LOGIN_CONTAINER>
        </>
      )}
    </>
  );
}

export default Login;
