import React from "react";
import { H1, A, SUPPORT_WRAPPER, WRAPPER_LINKS } from "./elements";

const Support = () => {
  return (
    <SUPPORT_WRAPPER>
      <WRAPPER_LINKS>
        <H1>Support Us</H1>
        <A href="https://github.com/sponsors/MysticalMike60t">
          <div className="loader-heart"></div>
        </A>
      </WRAPPER_LINKS>
    </SUPPORT_WRAPPER>
  );
};

export default Support;
