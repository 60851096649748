import React, { useEffect, useState, useCallback, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { LoaderFormatted } from "../../global/modules";
import { ErrorMessage } from "../../global/modules/elements";
import { auth, logout } from "../../../hooks/firebase";
import {
  praporIcon,
  therapistIcon,
  fenceIcon,
  skierIcon,
  peacekeeperIcon,
  mechanicIcon,
  ragmanIcon,
  jaegerIcon,
} from "../../../assets/images/icons/tarkov";
import {
  settingsIcon,
  crossIcon,
  homeIcon,
} from "../../../assets/images/icons";
import {
  DashboardModalForm,
  Divider,
  DividerAndMargin,
  EmailDisplay,
  NameDisplay,
  PleaseLoginMessage,
  SetTarkovEditionButton,
  TarkovEditionDropdown,
  TraderIcon,
} from "./modules/elements";
import { Time } from "./modules/calc";
import { allowedUserUids } from "../../../data";
import updatePmcLevel from "./functions/updatePmcLevel";
import {
  decrementTaskProgress,
  fetchTasks,
  fetchUserData,
  goHome,
  handlePmcLevelChange,
  handleTarkovEditionChange,
  incrementTaskProgress,
  resetCompletedTasks,
  resetTaskProgress,
  updateTarkovEdition,
} from "./functions";

function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [pmcLevel, setPmcLevel] = useState("");
  const [pmcLevelInput, setPmcLevelInput] = useState("");
  const [tarkovEdition, setTarkovEdition] = useState("");
  const [tarkovEditionInput, setTarkovEditionInput] = useState("");
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(
    window.innerWidth < 680
  );
  const [tasks, setTasks] = useState([]);
  const [taskProgress, setTaskProgress] = useState({});

  const modalOverlayRef = useRef(null);

  const navigate = useNavigate();

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);

  useEffect(() => {
    const handleResize = () => {
      setIsButtonVisible(window.innerWidth < 680);
      if (window.innerWidth < 680) {
        setIsContentVisible(false);
      } else {
        setIsContentVisible(true);
      }
    };

    setIsButtonVisible(window.innerWidth < 680);

    window.addEventListener("resize", handleResize);

    if (window.innerWidth < 680) {
      setIsContentVisible(false);
    } else {
      setIsContentVisible(true);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [loading, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (loading) return;
      if (!user) return navigate("/");

      try {
        await fetchUserData(
          user,
          setName,
          setPmcLevel,
          setTarkovEdition,
          setCompletedTasks
        );
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchData();
  }, [loading, navigate, user]);

  useEffect(() => {
    const fetchUserTasks = async () => {
      try {
        await fetchTasks(user, completedTasks, setTasks, setTaskProgress);
      } catch (error) {
        console.error("Error fetching tasks: ", error);
      }
    };

    fetchUserTasks();
  }, [user, completedTasks]);

  const handleCloseModal = useCallback(
    (e) => {
      if (modalOverlayRef.current && e.target === modalOverlayRef.current) {
        toggleEditMode();
      }
    },
    [toggleEditMode]
  );

  useEffect(() => {
    window.addEventListener("click", handleCloseModal);

    return () => {
      window.removeEventListener("click", handleCloseModal);
    };
  }, [handleCloseModal]);

  return (
    <>
      {loading ? (
        <>
          <LoaderFormatted />
        </>
      ) : (
        <>
          <div className="container__page dashboard">
            {isEditMode && (
              <div className="modal-overlay" ref={modalOverlayRef}>
                <div className="modal">
                  <DashboardModalForm>
                    <input
                      type="number"
                      placeholder="Enter PMC Level"
                      value={pmcLevelInput}
                      onChange={(e) =>
                        handlePmcLevelChange(e, setPmcLevelInput)
                      }
                    />
                    {pmcLevelInput === "" && (
                      <ErrorMessage>PMC Level cannot be empty</ErrorMessage>
                    )}
                    <button
                      className="dashboard__btn"
                      onClick={() =>
                        updatePmcLevel(user, pmcLevelInput, setPmcLevel)
                      }
                      disabled={pmcLevelInput === ""}
                    >
                      Update PMC Level
                    </button>
                    <TarkovEditionDropdown
                      className="input"
                      value={tarkovEditionInput}
                      onChange={(e) =>
                        handleTarkovEditionChange(e, setTarkovEditionInput)
                      }
                    />
                    <SetTarkovEditionButton
                      user={user}
                      tarkovEditionInput={tarkovEditionInput}
                      setTarkovEdition={setTarkovEdition}
                      updateTarkovEdition={updateTarkovEdition}
                    />
                    <button className="close" onClick={toggleEditMode}>
                      Close
                    </button>
                  </DashboardModalForm>
                </div>
              </div>
            )}
            {user ? (
              isContentVisible && (
                <div
                  className={`dashboard__container ${
                    isContentVisible ? "" : "hidden"
                  }`}
                >
                  {isButtonVisible && (
                    <div
                      className="dashboard__container before"
                      style={{ boxShadow: "none", marginBottom: "10px" }}
                    >
                      <button
                        className="head-button"
                        onClick={toggleContentVisibility}
                      >
                        <img src={crossIcon} alt="" />
                      </button>
                    </div>
                  )}
                  <Link to="/" className="backtohome">
                    Back to home
                  </Link>
                  Logged in as
                  <NameDisplay>{name}</NameDisplay>
                  <EmailDisplay>{user?.email}</EmailDisplay>
                  <DividerAndMargin />
                  <div
                    className={`level ${pmcLevel === "79" ? "largeFont" : ""}`}
                  >
                    PMC Level: <span>{pmcLevel}</span>
                  </div>
                  <div className="level">
                    Tarkov Edition: <span>{tarkovEdition}</span>
                  </div>
                  <DividerAndMargin />
                  <button className="dashboard__btn" onClick={toggleEditMode}>
                    {isEditMode ? "Cancel Edit" : "Edit Info"}
                  </button>
                  <Divider />
                  <h2>Reset Progress</h2>
                  <button
                    onClick={() =>
                      resetCompletedTasks(
                        user,
                        resetTaskProgress(user, setTaskProgress)
                      )
                    }
                    className="dashboard__btn"
                    style={{ padding: "0 10px" }}
                  >
                    Reset Tasks
                  </button>
                  <Divider />
                  <button
                    className="dashboard__btn"
                    onClick={logout}
                    style={{ marginTop: "20px" }}
                  >
                    Logout
                  </button>
                  {user && allowedUserUids.includes(user.uid) ? (
                    <Link to="/admin/task-form">Task Form</Link>
                  ) : (
                    <></>
                  )}
                </div>
              )
            ) : (
              <div className="dashboard__container">
                <PleaseLoginMessage />
              </div>
            )}

            {!isContentVisible && window.innerWidth <= 680 && (
              <div className="dashboard__container before">
                <button
                  className="head-button"
                  onClick={() => goHome(navigate)}
                >
                  <img src={homeIcon} alt="" />
                </button>
                <button
                  className="head-button"
                  onClick={toggleContentVisibility}
                >
                  <img src={settingsIcon} alt="" />
                </button>
              </div>
            )}
            <div className="tarkov">
              <h1>
                <Time />
              </h1>
            </div>
            <div className="main">
              <div className="traders">
                <TraderIcon
                  icon={praporIcon}
                  name="Prapor"
                  link="/dashboard/prapor"
                />
                <TraderIcon
                  icon={therapistIcon}
                  name="Therapist"
                  link="/dashboard/therapist"
                />
                <TraderIcon
                  icon={fenceIcon}
                  name="Fence"
                  link="/dashboard/fence"
                />
                <TraderIcon
                  icon={skierIcon}
                  name="Skier"
                  link="/dashboard/skier"
                />
                <TraderIcon
                  icon={peacekeeperIcon}
                  name="Peacekeeper"
                  link="/dashboard/peacekeeper"
                />
                <TraderIcon
                  icon={mechanicIcon}
                  name="Mechanic"
                  link="/dashboard/mechanic"
                />
                <TraderIcon
                  icon={ragmanIcon}
                  name="Ragman"
                  link="/dashboard/ragman"
                />
                {pmcLevelInput < 2 ? (
                  <TraderIcon
                    icon={jaegerIcon}
                    name="Jaeger"
                    link="/dashboard/jaeger"
                    style={{ opacity: 0.1 }}
                  />
                ) : (
                  <TraderIcon
                    icon={jaegerIcon}
                    name="Jaeger"
                    link="/dashboard/jaeger"
                  />
                )}
              </div>
            </div>
            <div className="info">
              {tasks.length > 0 ? (
                tasks
                  .filter(
                    (task) => task.items && Object.keys(task.items).length > 0
                  )
                  .map((task, itemKey) => (
                    <div key={task.id} className="info__item">
                      <h1>{task.title}</h1>
                      {task.items && (
                        <ul className="item__items">
                          {Object.keys(task.items).map((itemId) => (
                            <li key={itemId}>
                              {task.items[itemId][0]} (Quantity:{" "}
                              {task.items[itemId][1]})
                              <div>
                                <span>
                                  {taskProgress[`${task.name}-${itemId}`] || 0}{" "}
                                  / {task.items[itemId][1]}
                                </span>
                                <span>
                                  <button
                                    className="btn add"
                                    onClick={() => {
                                      incrementTaskProgress(
                                        user,
                                        task.name,
                                        task.items[itemId][1],
                                        itemId,
                                        taskProgress,
                                        setTaskProgress
                                      );
                                    }}
                                  >
                                    +
                                  </button>
                                  <button
                                    className="btn minus"
                                    onClick={() => {
                                      decrementTaskProgress(
                                        user,
                                        task.name,
                                        itemId,
                                        taskProgress,
                                        setTaskProgress
                                      );
                                    }}
                                  >
                                    -
                                  </button>
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))
              ) : (
                <p>No tasks available</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
