import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzDi7wnlubeOsOzh8Mxz4zmS_9NyV3QLE",
  authDomain: "tarkov-expert.firebaseapp.com",
  projectId: "tarkov-expert",
  storageBucket: "tarkov-expert.appspot.com",
  messagingSenderId: "801382855891",
  appId: "1:801382855891:web:94d5fa48e3b3e8fbf7d026",
  measurementId: "G-SZNPN01QYN",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  } catch (err) {
    console.error(err);
    throw new Error("Login failed: " + err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};
function writeUserData(user) {
  if (user) {
    const usersCollection = collection(db, "users");

    addDoc(usersCollection, user)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  } else {
    console.error("Invalid user data: user is null");
  }
}
async function addInfoToUser(uid, dataToAdd) {
  const usersCollection = collection(db, "users");
  const userDoc = doc(usersCollection, uid);

  try {
    const userDocSnapshot = await getDoc(userDoc);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();

      const updatedData = { ...userData, ...dataToAdd };

      await setDoc(userDoc, updatedData);

      console.log("User data updated successfully.");
    } else {
      console.error("User document not found.");
    }
  } catch (error) {
    console.error("Error updating user data: ", error);
  }
}
const FirebaseInitializer = ({ children }) => {
  return children;
};
const usersCollection = collection(db, "users");
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  writeUserData,
  addInfoToUser,
  usersCollection,
  FirebaseInitializer,
};
