import { request, gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import { Loader } from "../../global/modules";
import { BackButton } from "../../global/modules/elements";
import {
  H1,
  INFO_CONTAINER,
  Items,
  PAGE_WRAPPER,
  UL,
} from "./modules/elements";

const query = gql`
  {
    items {
      id
      name
      shortName
      wikiLink
      iconLink
      updated
    }
  }
`;

const Info = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [shortNameSearchTerm, setShortNameSearchTerm] = useState("");

  useEffect(() => {
    setLoading(true);
    request("https://api.tarkov.dev/graphql", query)
      .then((data) => {
        setItems(data.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleNameSearch = (e) => {
    setNameSearchTerm(e.target.value);
  };

  const handleIdSearch = (e) => {
    setIdSearchTerm(e.target.value);
  };

  const handleShortNameSearch = (e) => {
    setShortNameSearchTerm(e.target.value);
  };

  const filteredItems = items.filter(
    (item) =>
      item.name.toLowerCase().includes(nameSearchTerm.toLowerCase()) &&
      item.id.toString().includes(idSearchTerm) &&
      item.shortName.toLowerCase().includes(shortNameSearchTerm.toLowerCase())
  );

  return (
    <INFO_CONTAINER>
      <BackButton />
      <PAGE_WRAPPER>
        <H1>Items</H1>
        <div className="search">
          <input
            type="text"
            placeholder="Search by name..."
            value={nameSearchTerm}
            onChange={handleNameSearch}
          />
          <input
            type="text"
            placeholder="Search by ID..."
            value={idSearchTerm}
            onChange={handleIdSearch}
          />
          <input
            type="text"
            placeholder="Search by short name..."
            value={shortNameSearchTerm}
            onChange={handleShortNameSearch}
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <UL>
            <Items items={filteredItems} />
          </UL>
        )}
      </PAGE_WRAPPER>
    </INFO_CONTAINER>
  );
};

export default Info;
