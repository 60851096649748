const addObjective = (
  taskObjectiveInput,
  taskObjective,
  setTaskObjective,
  setTaskObjectiveInput
) => {
  if (taskObjectiveInput.trim() !== "") {
    setTaskObjective([...taskObjective, taskObjectiveInput]);
    setTaskObjectiveInput("");
  }
};

export default addObjective;
