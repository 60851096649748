import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../../hooks/firebase";
import { register } from "./functions";
import {
  H1,
  PAGE_WRAPPER,
  REGISTER_CONTAINER,
  INPUT,
  RegisterWithGoogle,
  REGISTER_BTN,
} from "./modules/elements";
import { BackButton } from "../../../global/modules/elements";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading, navigate]);

  return (
    <REGISTER_CONTAINER>
      <BackButton />
      <PAGE_WRAPPER>
        <H1>Register</H1>
        <INPUT
          type="text"
          value={name}
          onChange={setName}
          placeholder="Full Name"
        />
        <INPUT
          type="text"
          value={email}
          onChange={setEmail}
          placeholder="E-mail Address"
        />
        <INPUT
          type="password"
          value={password}
          onChange={setPassword}
          placeholder="Password"
        />
        <REGISTER_BTN
          register={register}
          name={name}
          email={email}
          password={password}
        />
        <RegisterWithGoogle />
        <div>
          Already have an account? <Link to="/login">Login</Link> now.
        </div>
      </PAGE_WRAPPER>
    </REGISTER_CONTAINER>
  );
}
export default Register;
