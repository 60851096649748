import React from "react";
import { DESC_P } from "./elements";

const Description = () => {
  return (
    <DESC_P>
      Comprehensive guide to all your needs during the Escape from Tarkov wipe.
      <br />
      <br />
      <strong>This site is still under heavy development.</strong>
      <br />
      <br />
      Login below to get Started!
    </DESC_P>
  );
};

export default Description;
