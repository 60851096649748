import React, { useEffect, useState, useCallback } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../hooks/firebase";
import { Loader } from "../../../global/modules";
import { dirIcon } from "../../../../assets/images/icons";

const TraderPage = ({ traderName }) => {
  const traderNameCapitalized =
    traderName.charAt(0).toUpperCase() + traderName.slice(1);

  const [tasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchUserDocument = useCallback(async () => {
    try {
      if (!user || !user.uid) {
        console.error("No user or user UID found.");
        return null;
      }

      const userQuery = query(
        collection(db, "users"),
        where("uid", "==", user?.uid)
      );
      const userQuerySnapshot = await getDocs(userQuery);

      if (!userQuerySnapshot.empty) {
        return userQuerySnapshot.docs[0];
      } else {
        console.error("User document not found for UID: ", user.uid);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user document: ", error);
      return null;
    }
  }, [user]);

  const isTaskVisible = useCallback((task, completedTasks) => {
    // Check if the task itself is completed
    if (completedTasks.includes(task.title)) {
      return false; // Task is completed, not visible
    }

    // Check if the task has any requirements
    if (task.requirements && task.requirements.length > 0) {
      // Check if all requirements are completed
      const areRequirementsCompleted = task.requirements.every((requirement) =>
        completedTasks.includes(requirement)
      );

      if (!areRequirementsCompleted) {
        return false; // Task is not visible because some requirements are not completed
      }
    }

    // If no specific requirement or all requirements are met, the task is visible
    return true;
  }, []);

  const fetchTasks = useCallback(
    async (userDoc) => {
      try {
        if (!userDoc) {
          return;
        }

        setIsLoading(true); // Set loading to true when fetching tasks

        const userData = userDoc.data();
        const completedTasks = userData.completedTasks || [];

        const q = query(
          collection(db, "tasks"),
          where("trader", "==", traderNameCapitalized)
        );
        const querySnapshot = await getDocs(q);

        const taskData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const filteredTasks = taskData.filter((task) => {
          return isTaskVisible(task, completedTasks);
        });

        setTasks(filteredTasks);
        setIsLoading(false); // Set loading to false when tasks are fetched
      } catch (error) {
        console.error("Error fetching tasks: ", error);
      }
    },
    [isTaskVisible, traderNameCapitalized]
  );

  const markTaskCompleted = async (taskTitle) => {
    try {
      const userDoc = await fetchUserDocument();

      if (!userDoc) {
        return;
      }

      const userDocRef = userDoc.ref;
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const completedTasks = userData.completedTasks || [];

      if (!completedTasks.includes(taskTitle)) {
        completedTasks.push(taskTitle);
        await updateDoc(userDocRef, { completedTasks });
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task.title !== taskTitle)
        );
      }

      window.location.reload();
    } catch (error) {
      console.error("Error marking task as completed: ", error);
    }
  };

  useEffect(() => {
    const fetchAndSetTasks = async () => {
      const userDoc = await fetchUserDocument();
      fetchTasks(userDoc);
    };

    fetchAndSetTasks();
  }, [searchTerm, fetchTasks, fetchUserDocument]);

  return (
    <div className="subpage__wrapper">
      <h1>{traderNameCapitalized}</h1>
      <input
        type="text"
        placeholder="Search tasks by title"
        value={searchTerm}
        className="taskSearch"
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ zIndex: "+2" }}
      />
      {isLoading ? ( // Render loading screen if isLoading is true
        <div className="loading-screen">
          <Loader />
        </div>
      ) : (
        <div className="wrapper__section tasks">
          {tasks.map((task) => (
            <div key={task.id} className="tasks__task">
              <img src={dirIcon} width={40} alt="" />
              <Link to={`/dashboard/${traderName}/${task.id}`}>
                {task.title}
              </Link>
              {user && user.uid && (
                <button onClick={() => markTaskCompleted(task.title)}>
                  Mark as Completed
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TraderPage;
