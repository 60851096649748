import React, { useEffect } from "react";

import { auth } from "../../../hooks/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { useNavigate } from "react-router-dom";
import { LoaderFormatted } from "../../global/modules";
import { PageCover, Support, Bottom, Description, Guide } from "./modules";
import {
  DashboardAction,
  Title,
  HOME_CONTAINER,
  PAGE_WRAPPER_TOP,
  PAGE_DESC,
} from "./modules/elements";

const Home = () => {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      <LoaderFormatted />;
      return;
    }
  }, [user, loading, navigate]);
  return (
    <>
      {loading ? (
        <>
          <LoaderFormatted />
        </>
      ) : (
        <>
          <HOME_CONTAINER>
            <PAGE_WRAPPER_TOP>
              <Title />
              <PAGE_DESC>
                <Description />
              </PAGE_DESC>
              <DashboardAction user={user} />
            </PAGE_WRAPPER_TOP>
            <Bottom />
            <Guide />
            <PageCover />
            <Support />
          </HOME_CONTAINER>
        </>
      )}
    </>
  );
};

export default Home;
