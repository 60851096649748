import React from "react";

function TarkovEditionDropdown({ value, onChange }) {
  return (
    <select value={value} onChange={onChange}>
      <option value="Standard">Standard Edition</option>
      <option value="Left Behind">Left Behind Edition</option>
      <option value="Edge Of Darkness">Edge of Darkness Edition</option>
    </select>
  );
}

export default TarkovEditionDropdown;
