import Loader from "./Loader";

const LoaderFormatted = () => {
  return (
    <div className="container__page flex-center p-20 bg-default">
      <Loader />
    </div>
  );
};

export default LoaderFormatted;
