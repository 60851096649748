import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../../../hooks/firebase";

const fetchTasks = async (user, completedTasks, setTasks, setTaskProgress) => {
  try {
    const q = query(collection(db, "tasks"));
    const querySnapshot = await getDocs(q);

    const taskData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().title,
      taskProgress: doc.data().taskProgress,
      requirements: doc.data().requirements || [],
      ...doc.data(),
    }));

    const filteredTasks = taskData.filter((task) => {
      const userMeetsRequirements = task.requirements.every((requirement) =>
        completedTasks.includes(requirement)
      );

      if (!userMeetsRequirements) {
        return false;
      }

      return !completedTasks.includes(task.name);
    });

    setTasks(filteredTasks);

    const userRef = collection(db, "users");
    const userDocQuery = query(userRef, where("uid", "==", user?.uid));
    const userDocQuerySnapshot = await getDocs(userDocQuery);

    if (!userDocQuerySnapshot.empty) {
      const userDocData = userDocQuerySnapshot.docs[0].data();
      const userTaskProgress = userDocData.taskProgress || {};

      setTaskProgress(userTaskProgress);
    }
  } catch (error) {
    console.error("Error fetching tasks: ", error);
  }
};

export default fetchTasks;
