import {
  query,
  collection,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const decrementTaskProgress = async (
  user,
  taskName,
  itemId,
  taskProgress,
  setTaskProgress
) => {
  try {
    if (!user) return;

    const userRef = collection(db, "users");
    const q = query(userRef, where("uid", "==", user?.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];

      const userDocData = userDoc.data();
      const userTaskProgress = userDocData.taskProgress || {};

      const itemKey = `${taskName}-${itemId}`;

      if (
        userTaskProgress.hasOwnProperty(itemKey) &&
        userTaskProgress[itemKey] > 0
      ) {
        userTaskProgress[itemKey] -= 1;

        await updateDoc(userDoc.ref, { taskProgress: userTaskProgress });

        setTaskProgress({
          ...taskProgress,
          [itemKey]: userTaskProgress[itemKey],
        });
      } else {
        ToastErrorDefault("Task progress cannot be negative.");
      }
    } else {
      console.error("User document not found.");
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while decrementing task progress.");
  }
};

export default decrementTaskProgress;
