import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = ({ role, ariaLive, className, children }) => {
  return (
    <div className={`error-message ${className}`}>
      <span role={role} aria-live={ariaLive}>
        {children}
      </span>
    </div>
  );
};

ErrorMessage.propTypes = {
  role: PropTypes.string,
  ariaLive: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ErrorMessage.defaultProps = {
  role: "alert",
  ariaLive: "assertive",
  className: "",
};

export default ErrorMessage;
