import {
  query,
  collection,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";

const resetCompletedTasks = async (user, resetTaskProgress) => {
  const q = query(collection(db, "users"), where("uid", "==", user?.uid));
  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0].ref;

      await updateDoc(userDoc, { completedTasks: null });

      resetTaskProgress();
    } else {
      console.error("No documents found matching the query.");
    }
  } catch (error) {
    console.error("Error updating field: ", error);
  }
};

export default resetCompletedTasks;
