import {
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../../../../hooks/firebase";
import { ToastErrorDefault } from "../../../global/modules/elements";

const fetchUserData = async (
  user,
  setName,
  setPmcLevel,
  setTarkovEdition,
  setCompletedTasks
) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const docSnapshots = await getDocs(q);
    const docData = docSnapshots.docs[0].data();

    setName(docData.name);

    if (!docData.pmcLevel) {
      setPmcLevel(1);
    } else {
      setPmcLevel(docData.pmcLevel);
    }

    if (!docData.tarkovEdition) {
      setTarkovEdition("Standard Edition");
    } else {
      setTarkovEdition(docData.tarkovEdition);
    }

    if (docData.completedTasks) {
      setCompletedTasks(docData.completedTasks);
    }
  } catch (err) {
    console.error(err);
    ToastErrorDefault("An error occurred while fetching user data");
  }
};

export default fetchUserData;
