import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaskFormDropdown } from "./modules";
import {
  addObjective,
  addRequirement,
  addReward,
  handleTaskSubmit,
  handleTraderChange,
} from "./functions";

const TaskForm = () => {
  const [taskTitle, setTaskTitle] = useState("");
  const [taskMessage, setTaskMessage] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskObjective, setTaskObjective] = useState([]);
  const [taskObjectiveInput, setTaskObjectiveInput] = useState("");
  const [taskTrader, setTaskTrader] = useState("");
  const [rewards, setRewards] = useState([]);
  const [rewardInput, setRewardInput] = useState("");
  const [requirements, setRequirements] = useState([]);
  const [requirementsInput, setRequirementsInput] = useState("");
  const [levelRequirement, setLevelRequirement] = useState("");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container__page dashboard subpage">
      <button className="backButtonUni" onClick={goBack}>
        Go Back
      </button>
      <div className="dashboard__container">
        <form
          onSubmit={(e) =>
            handleTaskSubmit(
              e,
              taskTrader,
              taskTitle,
              taskMessage,
              taskDescription,
              taskObjective,
              rewards,
              levelRequirement,
              requirements,
              setTaskTrader,
              setTaskTitle,
              setTaskMessage,
              setRewards,
              setRewardInput,
              setTaskDescription,
              setTaskObjective,
              setTaskObjectiveInput,
              setRequirements,
              setRequirementsInput,
              setLevelRequirement
            )
          }
          style={{ zIndex: "+2" }}
        >
          <TaskFormDropdown
            value={taskTrader}
            onChange={(selectedValue) =>
              handleTraderChange(setTaskTrader, selectedValue)
            }
          />
          <input
            type="text"
            placeholder="Task Title"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
          />
          <div>
            <input
              type="text"
              placeholder="Task Objective"
              value={taskObjectiveInput}
              onChange={(e) => setTaskObjectiveInput(e.target.value)}
            />
            <button
              type="button"
              onClick={() =>
                addObjective(
                  taskObjectiveInput,
                  taskObjective,
                  setTaskObjective,
                  setTaskObjectiveInput
                )
              }
            >
              Add Objective
            </button>
          </div>
          {taskObjective.map((objective, index) => (
            <div key={index} className="objective__container">
              <span className="objective">{objective}</span>
            </div>
          ))}
          <input
            type="text"
            placeholder="Task Message"
            value={taskMessage}
            onChange={(e) => setTaskMessage(e.target.value)}
          />
          <input
            type="text"
            placeholder="Task Description"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
          />
          <input
            type="number"
            placeholder="Level Requirement"
            value={levelRequirement}
            onChange={(e) => setLevelRequirement(e.target.value)}
          />
          <div>
            <input
              type="text"
              placeholder="Requirements"
              value={requirementsInput}
              onChange={(e) => setRequirementsInput(e.target.value)}
            />
            <button
              type="button"
              onClick={() =>
                addRequirement(
                  requirementsInput,
                  requirements,
                  setRequirements,
                  setRequirementsInput
                )
              }
            >
              Add Requirement
            </button>
          </div>
          {requirements.map((requirement, index) => (
            <div key={index}>
              <input type="text" value={requirement} readOnly />
            </div>
          ))}
          <div>
            <input
              type="text"
              placeholder="Reward"
              value={rewardInput}
              onChange={(e) => setRewardInput(e.target.value)}
            />
            <button
              type="button"
              onClick={() =>
                addReward(rewardInput, rewards, setRewards, setRewardInput)
              }
            >
              Add Reward
            </button>
          </div>
          {rewards.map((reward, index) => (
            <div key={index}>
              <input type="text" value={reward} readOnly />
            </div>
          ))}
          <div className="divider"></div>
          <button type="submit">Add Task</button>
        </form>
      </div>
    </div>
  );
};

export default TaskForm;
