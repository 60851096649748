import React from "react";

const LOGIN_BTN = ({handleLogin, email, password}) => {
  return (
    <button className="login__btn" onClick={() => handleLogin(email, password)}>
      Login
    </button>
  );
};

export default LOGIN_BTN;
